body.landing {
  background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
}

.logo {
  width: 300px;
}

@media (max-width:600px) {
  .logo {
    width: 180px;
  }
}

h1 {
  margin-top: 0;
}

#root,
.App,
main {
  min-height: 100vh;
}

main {
  position: relative;
}

.hero-bg {
  z-index: -1;
  position: absolute;
  width: 120vw;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  height: 90vh;
  border-radius: 0 0 50% 50%;
  box-shadow: 1px 1px rgba(50, 50, 50, 0.1);
}

.landing {
  color: rgb(0, 0, 0);
  text-align: center;
}

input {
  width: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .hero-bg {
    width: 200vh;
  }
}

#privacy {
  position: relative;
  top: 3.5rem;
}

#privacy:hover {
  text-decoration: underline;
  cursor: pointer;
}